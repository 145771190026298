<template>
  <div class="puntosdeventa">
    <tabla titulo="Puntos De Venta" 
          :headers = "headers"
          :arreglo = "PuntosDeVentaGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import tabla from '@/components/puntosdeventa/index';

export default {
  name: 'puntosdeventa',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarPuntosDeVenta()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        { text: 'PUNTO DE VENTA', value: 'razon_social' },
        { text: 'FECHA REGISTRO', value: 'fecha_registro' },
        { text: 'DISPONIBLE', value: 'disponible_format' },
        { text: 'COMISIÓN', value: 'comision_format' },
        { text: 'ACTIVO', value: 'active' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  

  methods: {
    ...mapActions(['ConsultarPuntosDeVenta']),

  },

  computed:{
      ...mapGetters(['PuntosDeVentaGetter'])
  }
}
</script>
