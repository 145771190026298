<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              
              <v-col cols="12">
                <h2 class="text-center">Punto de Venta</h2>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.razon_social"
                  label="Razón Social *"
                  hint="Razón social del punto de venta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.dir"
                  label="Dirección *"
                  hint="Dirección del punto de venta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="number"
                  :disabled = "Accion == 'Editar'"
                  v-model="Item.disponible"
                  label="Disponible *"
                  hint="Se debe realizar una primera carga de $30.000 que puedes utilizar en la compra de productos."
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
                v-show = "Accion == 'Editar'"
              >
                <v-text-field
                  disabled
                  v-model="Item.comision"
                  label="Comisión *"
                  hint="Fecha en que se creo el punto de venta."
                  persistent-hint
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
                v-show = "Accion == 'Editar'"
              >
                <v-text-field
                  disabled
                  v-model="Item.puntos"
                  label="Puntos *"
                  hint="Fecha en que se creo el punto de venta."
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="Accion == 'Editar'"
              >
               <v-select
                  v-model="Item.activo"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Punto de Venta está activo?"
                  hint="Indique si el punto de venta se encuentra activo en el sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="DatosdeUsuario.perfil <= 1"
              >
               <v-select
                  v-model="Item.membresia"
                  :items="SINO"
                  :disabled="DatosdeUsuario.perfil > 1"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Punto de Venta paga membresia?"
                  hint="Indique si el punto de venta paga membresia para descuentos."
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                 v-show = "Accion == 'Editar'"
              >
                <v-text-field
                  disabled
                  v-model="Item.fecha_registro"
                  label="Fecha de Registro *"
                  hint="Fecha en que se registró el punto de venta."
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
               >
                <v-textarea
                  auto-grow
                  counter
                  label="Notas"
                  v-model="Item.notas"
                  hint="Escriba comentarios acerca del punto de venta."
                  persistent-hint
                  rows="1"
                  required
                ></v-textarea>
              </v-col>

              <v-col cols="12" v-show = "Accion == 'Nuevo'" class="mt-5">
                <h2 class="text-center">Usuario administrador del Punto de Venta</h2>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show = "Accion == 'Nuevo'"
              >
                <v-text-field
                  v-model="Item.usernombre"
                  label="Nombre *"
                  hint="Nombre completo del usuario del sistema"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show = "Accion == 'Nuevo'"
              >
                <v-text-field
                  type="number"
                  v-model="Item.userusuario"
                  label="Usuario *"
                  hint="Número de cedula del usuario registrado"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                  v-show = "Accion == 'Nuevo'"
              >
                <v-text-field
                  type="number"
                  v-model="Item.usercelular"
                  label="Celular *"
                  hint="Número de celular del usuario del sistema"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show = "Accion == 'Nuevo'"
              >
                <v-text-field
                  type="email"
                  v-model="Item.useremail"
                  label="Email *"
                  hint="Correo electrónico"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-show = "Accion == 'Nuevo'">
                <v-textarea
                  auto-grow
                  counter
                  label="Notas"
                  v-model="Item.usernotas"
                  hint="Escriba comentarios acerca del usuario."
                  rows="1"
                  persistent-hint
                  required
                ></v-textarea>
              </v-col>

            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="Ocultar()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>


     <v-dialog
      v-model="DialogAceptar"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{Accion}}
        </v-card-title>
        <v-card-text>¿Estas seguro de guardar?.</v-card-text>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
             @click="DialogAceptar = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="Guardar()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  data: () => ({
    var: null,
    DialogAceptar: false,
    ItemEnviar: {
        id: null, 
        razon_social: null, 
        dir: null, 
        disponible: 30000, 
        activo: null,
        membresia: null, 
        notas: null,

        usernombre: null,
        userusuario: null,
        usercelular: null,
        useremail: null,
        usernotas: null
    }
  }),

  methods: {
     ...mapActions(['NuevoPuntodeVenta', 'ConsultarPuntosDeVenta']),

    Guardar (){
      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.razon_social = this.Item.razon_social
      this.ItemEnviar.dir = this.Item.dir
      this.ItemEnviar.disponible = this.Item.disponible
      this.ItemEnviar.activo = this.Item.activo
      this.ItemEnviar.membresia = this.Item.membresia
      this.ItemEnviar.notas = this.Item.notas

      this.ItemEnviar.usernombre = this.Item.usernombre
      this.ItemEnviar.userusuario = this.Item.userusuario
      this.ItemEnviar.usercelular = this.Item.usercelular
      this.ItemEnviar.useremail = this.Item.useremail
      this.ItemEnviar.usernotas = this.Item.usernotas

      this.NuevoPuntodeVenta(this.ItemEnviar)
      setTimeout(() => { this.ConsultarPuntosDeVenta() }, 500);
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO','DatosdeUsuario'])
  }


})
</script>
