<template>
<div>

    <Nuevo  v-if="NuevoActivo" :NuevoActivo="NuevoActivo" :Accion="Accion" :Item="Item" @NuevoActivo="NuevoActivo = $event"/>

  <v-data-table
    :headers="headers"
    :items="arreglo"
    :items-per-page="5"
    :search="busqueda"
    dense
    sort-by="id"
    sort-desc="[true]"
    class="elevation-12 mt-5"
    :loading="TablaVacia"
    loading-text="Cargando... Espere por favor"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
      
      <v-toolbar-title>{{titulo}}</v-toolbar-title>
    
        <v-spacer></v-spacer>
        <!--busqueda-->
        <v-text-field
            v-model="busqueda"
            append-icon="mdi-magnify"
            label="BUSQUEDA"
            single-line
            hide-details
        ></v-text-field>
        <!--busqueda-->
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          dark
          @click="newItem()"
        >
          NUEVO
        </v-btn>

         <!--Popup DELETE-->
        <v-dialog v-model="dialogDelete" max-width="380px">
          <v-card>
            <v-card-title>¿Desea eliminar el ID # {{ItemEliminar.id}}?</v-card-title>
            <v-card-subtitle>Nombre: {{ItemEliminar.razon_social}}</v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="closeDelete">Cerrar</v-btn>
              <v-btn color="primary" @click="deleteItemConfirm">Eliminar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--Popup DELETE-->

         <!--Popup Disponible-->
        <v-dialog v-model="dialogDisponible" max-width="360px">
          <v-card>
            <v-toolbar
              color="success"
              dark
            >
              AGREGAR SALDO AL PUNTO DE VENTA # {{ItemDisponible.id}}
            </v-toolbar>

            <v-card-subtitle class="mt-2">
              <h2>Punto de Venta: {{ItemDisponible.razon_social}}</h2>
            </v-card-subtitle>

              <v-col
                cols="10"
                class="mx-auto mt-n6"
              >
              <v-text-field
                  type = "number"
                  v-model="ItemDisponible.disponible"
                  label="Valor a agregar *"
                  hint="Valor a agregar al punto de venta."
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col
                cols="10"
                class="mx-auto mt-n6"
              >
              <v-textarea
                  auto-grow
                  counter
                  rows="1"
                  label="Nota"
                  v-model="ItemDisponible.notas"
                  hint="Escriba algún comentario."
                  persistent-hint
                  required
                ></v-textarea>
              </v-col>

            <v-divider></v-divider>

            <v-card-actions class="mt-2">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialogDisponible = false">Cerrar</v-btn>
              <v-btn v-show="ItemDisponible.disponible" color="primary" @click="cargarDisponibleConfirm">Agregar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--Popup Disponible-->

      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Editar</span>
    </v-tooltip>
      
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <span>Eliminar</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="cargarDisponible(item)"
        >
          mdi-cash-plus
        </v-icon>
      </template>
      <span>Cargar Disponible</span>
    </v-tooltip>

    </template>

  </v-data-table>
  </div>
</template>

<script>
  import Nuevo from "./nuevo"
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    components: {
        Nuevo,
    },

    props:['titulo','headers','arreglo'],

    created () {
      this.initialize()
    },

    data: () => ({
      busqueda: '',

      Item: null,
      Accion: null,

      TablaVacia: null,
      items: [],
      
      NuevoActivo: false,

      dialogDelete: false,
      dialogDisponible: false,
      ItemDisponible: {id: null, disponible: null, razon_social: null, notas: null},
      ItemEliminar: {id: 0, nombre: ''}
    }),

    watch:{
      arreglo(){
          if(!!this.arreglo){
            if(this.arreglo.lenght > 0){
              this.TablaVacia = true
            }else{
              this.TablaVacia = false
            }
          }
        },

        dialogDelete (val) {
          val || this.closeDelete()
        },
    },

    methods: {
      ...mapMutations([]),
      ...mapActions(['ConsultarPuntosDeVenta','EliminarPuntodeVenta','AnadiraDisponible']),

      initialize () {
        
      },

      newItem (){
        this.Item= {
          id: 0,
          razon_social: null,
          dir: null,
          disponible: 30000,
          activo: 1,
          notas: null,

          usernombre: null,
          userusuario: null,
          usercelular: null,
          useremail: null,
          usernotas: null
          }
        this.NuevoActivo = true
        this.Accion = 'Nuevo'
      },

      editItem (item) {
        this.Item = Object.assign({}, item)
        this.NuevoActivo = true
        this.Accion = 'Editar'
      },

      deleteItem (item) {
        this.ItemEliminar= Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        await this.EliminarPuntodeVenta(this.ItemEliminar)
        this.closeDelete()
        await this.ConsultarPuntosDeVenta()
      },

      closeDelete () {
        this.dialogDelete = false
      },

      cargarDisponible (item){
        this.ItemDisponible.id = item.id
        this.ItemDisponible.razon_social = item.razon_social
        this.dialogDisponible = true
      },

      async cargarDisponibleConfirm(){
        await this.AnadiraDisponible(this.ItemDisponible)
        await this.ConsultarPuntosDeVenta()
        this.dialogDisponible = false
        this.ItemDisponible = {id: null, disponible: null, razon_social: null, notas: null}
      }

    },

    computed: {
      ...mapState([]),
      ...mapGetters([]),
    },
  }
</script>